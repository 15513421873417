import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Checklist } from "types";
import { currentTimeUnixTimestamp } from "helpers";

export interface ChecklistState {
  value: Checklist;
}

declare const window: {
  checklist: Checklist;
} & Window;

const initialState: ChecklistState = {
  value: window.checklist,
};

export const checklistSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    deleteCategory: (
      state,
      action: PayloadAction<{
        id: string;
        checklistId: string;
        categoryName: string;
        authorUsername: string;
        suggestionId?: string;
      }>,
    ) => {
      const { id, checklistId, categoryName, authorUsername, suggestionId } =
        action.payload;
      const category = state.value.categories.find(
        (_) => _.name === categoryName,
      )!;

      state.value.categories = state.value.categories.filter(
        (_) => _.name != categoryName,
      );

      state.value.changes.unshift({
        id: id,
        author_username: authorUsername,
        created_at: currentTimeUnixTimestamp(),
        name: "deleteCategory",
        checklist_id: checklistId,
        suggestion_id: suggestionId,
        payload: {
          categoryName: category.name,
          itemLength: category.items.length,
        },
      });
    },
    renameCategory: (
      state,
      action: PayloadAction<{
        id: string;
        checklistId: string;
        newCategoryName: string;
        oldCategoryName: string;
        authorUsername: string;
        suggestionId?: string;
      }>,
    ) => {
      const {
        id,
        checklistId,
        newCategoryName,
        oldCategoryName,
        authorUsername,
        suggestionId,
      } = action.payload;
      const category = state.value.categories.find(
        (category) => category.name === oldCategoryName,
      )!;

      const existingCategoryWithTheSameName = state.value.categories.find(
        (category) =>
          category.name === newCategoryName ||
          (category.name === null && newCategoryName === ""),
      )!;

      if (existingCategoryWithTheSameName) {
        existingCategoryWithTheSameName.items.push(...category.items);
        state.value.categories = state.value.categories.filter(
          (_) => _.name != oldCategoryName,
        );
      } else {
        category.name = newCategoryName;
      }

      state.value.changes.unshift({
        id: id,
        name: "renameCategory",
        author_username: authorUsername,
        created_at: currentTimeUnixTimestamp(),
        checklist_id: checklistId,
        suggestion_id: suggestionId,
        payload: {
          oldCategoryName: oldCategoryName,
          newCategoryName: newCategoryName,
        },
      });
    },
    deleteItem: (
      state,
      action: PayloadAction<{
        id: string;
        itemId: string;
        authorUsername: string;
        checklistId: string;
        itemName: string;
        categoryName: string;
        suggestionId?: string;
      }>,
    ) => {
      const {
        id,
        itemId,
        authorUsername,
        checklistId,
        itemName,
        categoryName,
        suggestionId,
      } = action.payload;
      const category = state.value.categories.find((category) =>
        category.items.find((_) => _.id === itemId),
      );

      if (!category) {
        throw new Error("No category found");
      }

      category.items = category.items.filter((item) => item.id != itemId);

      if (category.items.length == 0) {
        state.value.categories = state.value.categories.filter(
          (_) => _.id != category.id,
        );
      }

      state.value.changes.unshift({
        id: id,
        name: "deleteItem",
        author_username: authorUsername,
        created_at: currentTimeUnixTimestamp(),
        checklist_id: checklistId,
        suggestion_id: suggestionId,
        payload: {
          itemId: itemId,
          itemName: itemName,
          categoryName: categoryName,
        },
      });
    },
    renameItem: (
      state,
      action: PayloadAction<{
        id: string;
        authorUsername: string;
        checklistId: string;
        itemId: string;
        oldItemName: string;
        newItemName: string;
        categoryName: string;
        suggestionId?: string;
      }>,
    ) => {
      const {
        id,
        authorUsername,
        checklistId,
        itemId,
        oldItemName,
        newItemName,
        categoryName,
        suggestionId,
      } = action.payload;

      const item = state.value.categories
        .flatMap((_) => _.items)
        .find((_) => _.id == itemId)!;

      item.name = newItemName;

      state.value.changes.unshift({
        id: id,
        name: "renameItem",
        author_username: authorUsername,
        created_at: currentTimeUnixTimestamp(),
        checklist_id: checklistId,
        suggestion_id: suggestionId,
        payload: {
          itemId: itemId,
          oldItemName: oldItemName,
          newItemName: item.name,
          categoryName: categoryName,
        },
      });
    },
    updateItemCategory: (
      state,
      action: PayloadAction<{
        id: string;
        checklistId: string;
        authorUsername: string;
        itemId: string;
        itemName: string;
        oldCategoryName: string;
        newCategoryName: string;
        suggestionId?: string;
      }>,
    ) => {
      const {
        id,
        checklistId,
        authorUsername,
        itemId,
        itemName,
        oldCategoryName,
        newCategoryName,
        suggestionId,
      } = action.payload;

      const oldCategory = state.value.categories.find((_) =>
        _.items.find((_) => _.id === itemId),
      );

      if (!oldCategory) {
        throw new Error("Old category not found");
      }

      const item = oldCategory.items.find((_) => _.id === itemId);

      if (!item) {
        throw new Error("Item not found");
      }

      if (oldCategory.items.length == 1) {
        state.value.categories = state.value.categories.filter(
          (category) => category.name != oldCategoryName,
        );
      } else {
        oldCategory.items = oldCategory.items.filter(
          (item) => item.id != itemId,
        );
      }

      let newCategory = state.value.categories.find(
        (_) => _.name === newCategoryName,
      );

      const categoryId = newCategory?.id || crypto.randomUUID();

      const newItem = {
        id: itemId,
        name: item.name,
        position: 0,
        checked: item.checked,
        category: newCategoryName,
        category_id: categoryId,
      };

      if (newCategory) {
        newCategory.items.unshift(newItem);
      } else {
        newCategory = {
          id: categoryId,
          name: newCategoryName,
          items: [newItem],
        };
        state.value.categories.unshift(newCategory);
      }

      state.value.changes.unshift({
        id: id,
        name: "updateItemCategory",
        author_username: authorUsername,
        created_at: currentTimeUnixTimestamp(),
        checklist_id: checklistId,
        suggestion_id: suggestionId,
        payload: {
          itemId: item.id,
          itemName: itemName,
          oldCategoryName: oldCategoryName,
          newCategoryName: newCategory.name,
        },
      });
    },
    checkUncheckItem: (
      state,
      action: PayloadAction<{
        id: string;
        checklistId: string;
        authorUsername: string;
        suggestionId?: string;
        itemId: string;
        itemName: string;
        checked: boolean;
      }>,
    ) => {
      const {
        id,
        checklistId,
        authorUsername,
        suggestionId,
        itemId,
        itemName,
        checked,
      } = action.payload;

      const item = state.value.categories
        .flatMap((_) => _.items)
        .find((_) => _.id == itemId)!;

      const category = state.value.categories.find(
        (_) => _.id === item.category_id,
      )!;

      item.checked = checked;

      const uncheckedItems = category.items.filter((_) => !_.checked);
      const checkedItems = category.items.filter((_) => _.checked);

      category.items = [...uncheckedItems, ...checkedItems];

      state.value.changes.unshift({
        id: id,
        name: "checkUncheckItem",
        author_username: authorUsername,
        created_at: currentTimeUnixTimestamp(),
        checklist_id: checklistId,
        suggestion_id: suggestionId,
        payload: {
          itemId: item.id,
          itemName: itemName,
          checked: checked,
        },
      });
    },
    moveItem: (
      state,
      action: PayloadAction<{
        id: string;
        checklistId: string;
        authorUsername: string;
        itemId: string;
        itemName: string;
        oldCategoryName: string;
        newCategoryName: string;
        oldItemPosition: number;
        newItemPosition: number;
        suggestionId?: string;
      }>,
    ) => {
      const {
        id,
        checklistId,
        authorUsername,
        itemId,
        itemName,
        oldCategoryName,
        newCategoryName,
        oldItemPosition,
        newItemPosition,
        suggestionId,
      } = action.payload;

      const item = state.value.categories
        .flatMap((_) => _.items)
        .find((_) => _.id == itemId)!;

      const oldCategory = state.value.categories.find(
        (_) => _.name === oldCategoryName,
      )!;

      const newCategory = state.value.categories.find(
        (_) => _.name === newCategoryName,
      )!;

      item.category = newCategoryName;
      item.category_id = newCategory.id;

      // 1. delete item from old category
      oldCategory.items = oldCategory.items.filter((_) => _.id != item.id);

      // 2. insert item into the new category in new position
      newCategory.items.splice(newItemPosition - 1, 0, item);

      if (oldCategory.items.length === 0) {
        state.value.categories = state.value.categories.filter(
          (_) => _.id !== oldCategory.id,
        );
      }

      state.value.changes.unshift({
        id: id,
        name: "moveItem",
        author_username: authorUsername,
        created_at: currentTimeUnixTimestamp(),
        checklist_id: checklistId,
        suggestion_id: suggestionId,
        payload: {
          itemId: itemId,
          itemName: itemName,
          oldCategoryName: oldCategoryName,
          newCategoryName: newCategoryName,
          oldItemPosition: oldItemPosition,
          newItemPosition: newItemPosition,
        },
      });
    },
    moveCategory: (
      state,
      action: PayloadAction<{
        id: string;
        checklistId: string;
        authorUsername: string;
        categoryName: string;
        oldPosition: number;
        newPosition: number;
        suggestionId?: string;
      }>,
    ) => {
      const {
        id,
        checklistId,
        authorUsername,
        categoryName,
        oldPosition,
        newPosition,
        suggestionId,
      } = action.payload;

      const category = state.value.categories.find(
        (_) => _.name == categoryName,
      )!;

      state.value.categories = state.value.categories.filter(
        (_) => _.id != category.id,
      );

      state.value.categories.splice(newPosition - 1, 0, category);

      state.value.changes.unshift({
        id: id,
        name: "moveCategory",
        author_username: authorUsername,
        created_at: currentTimeUnixTimestamp(),
        checklist_id: checklistId,
        suggestion_id: suggestionId,
        payload: {
          categoryName: categoryName,
          oldPosition: oldPosition,
          newPosition: newPosition,
        },
      });
    },
    createItem: (
      state,
      action: PayloadAction<{
        id: string;
        checklistId: string;
        itemName: string;
        itemId: string;
        categoryName: string;
        authorUsername: string;
        suggestionId?: string;
      }>,
    ) => {
      const {
        id,
        checklistId,
        itemName,
        itemId,
        categoryName,
        authorUsername,
        suggestionId,
      } = action.payload;

      let category = state.value.categories.find(
        (_) => _.name === categoryName,
      );

      const categoryId = category?.id || crypto.randomUUID();

      if (!category) {
        category = {
          id: categoryId,
          name: categoryName,
          items: [],
        };

        state.value.categories.unshift(category);
      }

      category.items.unshift({
        id: itemId,
        name: itemName,
        category: categoryName,
        category_id: categoryId,
        checked: false,
      });

      state.value.changes.unshift({
        id: id,
        name: "createItem",
        author_username: authorUsername,
        created_at: currentTimeUnixTimestamp(),
        checklist_id: checklistId,
        suggestion_id: suggestionId,
        payload: {
          itemId: itemId,
          itemName: itemName,
          categoryName: categoryName,
        },
      });
    },
  },
});

export const {
  deleteCategory,
  renameCategory,
  deleteItem,
  renameItem,
  updateItemCategory,
  checkUncheckItem,
  moveItem,
  moveCategory,
  createItem,
} = checklistSlice.actions;

export const actions = checklistSlice.actions;

export default checklistSlice.reducer;
